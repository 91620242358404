<template>
    <div class="ds__page">
        <div class="row">
            <div class="column m:column--6/12">
                <header class="ds__header">
                    <h1 class="title ds__title">
                        Buttons
                    </h1>
                </header>

                <section class="ds__section">
                    <header class="ds__header">
                        <h2 class="subtitle ds__subtitle">
                            Main
                        </h2>
                    </header>

                    <div class="ds__sub-section button-group">
                        <button class="button">
                            Button
                        </button>
                        <button class="button" disabled>
                            Disabled
                        </button>
                    </div>

                    <div class="ds__sub-section button-group">
                        <button class="button button--primary">
                            Primary
                        </button>
                        <button class="button button--primary" disabled>
                            Primary Disabled
                        </button>
                    </div>

                    <div class="ds__sub-section button-group">
                        <button class="button button--secondary">
                            Secondary
                        </button>
                        <button class="button button--secondary" disabled>
                            Secondary Disabled
                        </button>
                    </div>

                    <div class="ds__sub-section button-group">
                        <button class="button button--tertiary">
                            Tertiary
                        </button>
                        <button class="button button--tertiary" disabled>
                            Tertiary Disabled
                        </button>
                    </div>
                </section>

                <section class="ds__section">
                    <header class="ds__header">
                        <h2>Colors</h2>
                    </header>

                    <div class="ds__sub-section button-group">
                        <button class="button button--green">
                            Green
                        </button>
                        <button class="button button--green" disabled>
                            Green Disabled
                        </button>
                    </div>
                </section>

                <section class="ds__section">
                    <header class="ds__header">
                        <h2 class="subtitle ds__subtitle">
                            Types
                        </h2>
                    </header>

                    <div class="ds__sub-section button-group">
                        <button class="button button--rounded">
                            Rounded
                        </button>
                        <button class="button button--rounded" disabled>
                            Rounded Disabled
                        </button>
                    </div>

                    <div class="ds__sub-section button-group">
                        <button class="button button--clean button--close">
                            <span class="button__text">&times;</span>
                        </button>
                    </div>

                    <div class="ds__sub-section button-group">
                        <button class="button button--ghost">
                            Ghost
                        </button>
                        <button class="button button--ghost" disabled>
                            Ghost Disabled
                        </button>
                    </div>

                    <div class="ds__sub-section button-group">
                        <button class="button button--clean">
                            Clean
                        </button>
                        <button class="button button--clean" disabled>
                            Clean Disabled
                        </button>
                    </div>

                    <div class="ds__sub-section button-group">
                        <button class="button button--link">
                            Link
                        </button>
                        <button class="button button--link" disabled>
                            Link Disabled
                        </button>
                    </div>
                </section>

                <section class="ds__section">
                    <header class="ds__header">
                        <h2 class="subtitle ds__subtitle">
                            Sizes
                        </h2>
                    </header>

                    <div class="ds__sub-section button-group">
                        <button class="button button--tiny">
                            Tiny Button
                        </button>
                    </div>
                    <div class="ds__sub-section button-group">
                        <button class="button button--small">
                            Small Button
                        </button>
                    </div>
                    <div class="ds__sub-section button-group">
                        <button class="button button--medium">
                            Large Button
                        </button>
                    </div>
                    <div class="ds__sub-section button-group">
                        <button class="button button--huge">
                            Huge Button
                        </button>
                    </div>
                </section>
            </div>
            <div class="column m:column--6/12">
                <header class="ds__header">
                    <h1 class="title ds__title">
                        Links
                    </h1>
                </header>

                <section class="ds__section">
                    <header class="ds__header">
                        <h2 class="subtitle ds__subtitle">
                            Types
                        </h2>
                    </header>

                    <div class="ds__sub-section button-group">
                        <a href="#" class="link">Link</a>
                        <a href="#" class="link link--underline">
                            Link underline
                        </a>
                        <a href="#" class="link link--hover-underline">
                            Link hover underline
                        </a>
                    </div>

                    <div>
                        <a
                            href="#"
                            class="link link--with-icon link--underline"
                        >
                            <AppIcon
                                name="attachment"
                                variant="spacing-right"
                            />
                            <span class="link__text">Bestandsnaam.pdf</span>
                        </a>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";
export default {
    name: "DsButtons",
    components: { AppIcon },
    data() {
        return {
            buttonToggleActive: false
        };
    }
};
</script>
